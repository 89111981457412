/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react'

import { LinkMock, LinkMockTargetBlank } from '../../components/LinkMock'
import { ListItemHTMLExample } from '../../pages/listat-ja-taulukot/list'

import IconHTMLExample from './IconHTMLExample'

const dummySitemapBasic: Record<string, string[]> = {
  Oikopolut: [
    'Työnhakijaksi ilmoittautuminen',
    'Avoimet työpaikat',
    'Koulutukset ja palvelut',
    'Työnhakuprofiili',
    'Suositellut työpaikat',
    'Tutkimukset ja tilastot'
  ],
  Asiakaspalvelu: [
    'Henkilöasiakkaan asiakaspalvelu',
    'Työnantajan ja yrittäjän asiakaspalvelu',
    'Palvelut ja lomakkeet',
    'Palaute'
  ]
}
const dummySitemapExternal: Record<string, string[]> = {
  'Muualla verkossa': [
    'Työ- ja elinkeinoministeriö',
    'Work in Finland',
    'KEHA-keskus',
    'Suomi.fi valtuudet'
  ]
}
const dummySitemapSome: Record<string, string> = {
  Twitter: 'Viestipalvelu X',
  Facebook: 'Facebook',
  Youtube: 'Youtube',
  Linkedin: 'LinkedIn'
}
const dummyFooterLinks = [
  'Tietoa palvelusta',
  'Käyttöehdot',
  'Tietosuojaseloste',
  'Ohjeet ja UKK',
  'Saavutettavuusseloste'
]

const SitemapColumn = ({
  heading,
  children
}: {
  heading: string
  children: React.ReactNode
}) => (
  <div className="col-xs-12 col-sm-6 col-lg-3">
    <h2 className="h6 mb-md">{heading}</h2>
    <ul className="list mb-lg-0">{children}</ul>
  </div>
)

const FooterSitemapHTMLExample = () => (
  <footer>
    <div className="page-footer-sitemap">
      <div className="page-footer-sitemap-content">
        <div className="row">
          {Object.entries(dummySitemapBasic).map(col => (
            <SitemapColumn heading={col[0]}>
              {col[1].map(item => (
                <ListItemHTMLExample>
                  <LinkMock>{item}</LinkMock>
                </ListItemHTMLExample>
              ))}
            </SitemapColumn>
          ))}

          {Object.entries(dummySitemapExternal).map(col => (
            <SitemapColumn heading={col[0]}>
              {col[1].map(item => (
                <ListItemHTMLExample>
                  <LinkMockTargetBlank>
                    {item}
                    <IconHTMLExample
                      ariaLabel="Avautuu uuteen välilehteen"
                      baseline
                      className="link-external-icon"
                      name="ExternalLinkIcon"
                    />
                  </LinkMockTargetBlank>
                </ListItemHTMLExample>
              ))}
            </SitemapColumn>
          ))}

          <SitemapColumn heading="Seuraa meitä">
            {Object.entries(dummySitemapSome).map(media => (
              <li className="list-item">
                <IconHTMLExample
                  className="list-item-icon"
                  name={`${media[0]}FillIcon`}
                  size="md"
                />
                <span className="list-item-label">
                  <LinkMockTargetBlank>
                    {media[1]}
                    <IconHTMLExample
                      ariaLabel="Avautuu uuteen välilehteen"
                      baseline
                      className="link-external-icon"
                      name="ExternalLinkIcon"
                    />
                  </LinkMockTargetBlank>
                </span>
              </li>
            ))}
          </SitemapColumn>
        </div>
      </div>
    </div>
    <div className="page-footer page-footer-sm">
      <div className="page-footer-content">
        <ul className="page-footer-links">
          <li className="page-footer-links-item">&copy; KEHA-keskus</li>
          {dummyFooterLinks.map(text => (
            <li className="page-footer-links-item">
              <a
                href="#"
                className="link-on-dark"
              >
                {text}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </footer>
)

export { FooterSitemapHTMLExample }
