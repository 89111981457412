import React from 'react'
import { Footer, FooterItem, Paragraph } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'
import { Code } from '../../components/Code'
import { FooterExample } from '../../examples/react/FooterExample'
import { FooterHTMLExample } from '../../examples/html/FooterHTMLExample'
import { FooterSitemapHTMLExample } from '../../examples/html/FooterSitemapHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Footer"
    components={[{ component: Footer }, { component: FooterItem }]}
    status={[{ type: 'accessible', version: '16.5.0' }]}
  >
    <Section>
      <Playground
        example={FooterExample}
        widePreview
      />
      <Playground
        format="html"
        widePreview
      >
        <FooterHTMLExample />
      </Playground>
    </Section>

    <Section title="Uusi footer">
      <Paragraph>
        3.0 -uudistusta varten lisätty uusi <Code>footer-sitemap</Code> -osio.
        Vanha footer pysyy mukana mutta ilmettä päivitetään lisäämällä sille{' '}
        <Code>page-footer-sm</Code> -lisä-class.
      </Paragraph>

      <Playground
        format="html"
        widePreview
      >
        <FooterSitemapHTMLExample />
      </Playground>
    </Section>
    <Section title="Saavutettavuus">
      <Paragraph>
        Footerin navigaatiossa pitää huomioida linkkien järjestys, sillä
        näppäimistöllä ja ruudunlukijalla linkit käydään läpi järjestyksessä.
      </Paragraph>
    </Section>
  </Content>
)

export default Page
