/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from 'react'

import {
  Button,
  FormGroup,
  Lead,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Textarea
} from '@te-digi/styleguide'

const FooterHTMLExample = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onModalClose = () => setIsModalOpen(false)

  return (
    <>
      <footer className="page-footer">
        <div className="page-footer-content">
          <ul className="page-footer-links">
            <li className="page-footer-links-item">&copy; KEHA-keskus</li>
            <li className="page-footer-links-item">
              <a
                href="#"
                className="link-on-dark"
              >
                Tietoa palvelusta
              </a>
            </li>
            <li className="page-footer-links-item">
              <a
                href="#"
                className="link-on-dark"
              >
                Käyttöehdot
              </a>
            </li>
            <li className="page-footer-links-item">
              <a
                href="#"
                className="link-on-dark"
              >
                Tietosuojaseloste
              </a>
            </li>
            <li className="page-footer-links-item">
              <a
                href="#"
                className="link-on-dark"
              >
                Ohjeet ja UKK
              </a>
            </li>
            <li className="page-footer-links-item">
              <a
                href="#"
                className="link-on-dark"
              >
                Saavutettavuusseloste
              </a>
            </li>
            <li className="page-footer-links-item">
              <button
                className="btn btn-plain-primary page-footer-button"
                onClick={() => setIsModalOpen(true)}
                type="button"
              >
                Lähetä palautetta
              </button>
            </li>
          </ul>
        </div>
      </footer>
      <Modal
        ariaLabelledby="modal-heading"
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        size="lg"
      >
        <ModalHeader onCloseButtonClick={onModalClose}>
          <ModalHeading id="modal-heading">Lähetä palautetta</ModalHeading>
        </ModalHeader>
        <ModalBody>
          <Lead>
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi,
            aliquam quisquam similique voluptates blanditiis illo hic minima
            consequatur error excepturi.
          </Lead>
          <FormGroup noMargin>
            <Textarea
              label="Lorem ipsum dolor sit amet"
              required
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onModalClose}
            variant="outline"
          >
            Peruuta
          </Button>
          <Button onClick={onModalClose}>Lähetä palaute</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export { FooterHTMLExample }
